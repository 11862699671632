<template>
  <div class="content">
    <div class="title-actions">
      <h5 class="title">{{$t('editInfoUser')}}</h5>
      <div class="btns">
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-form @submit.prevent="findUser" data-vv-scope="formFind">
      <div class="form-group">
        <label for="userId">{{$t('enterUserId')}}</label>
        <b-form-input v-validate="'required|numeric|min:1'" name="userId" id="userId" v-model="user.id" :state="validateState('userId')" data-vv-as="id" autofocus />
        <span class="error-inputs">{{ errors.first('userId') }}</span>
      </div>
      <b-button variant="primary" type="submit">{{$t('search')}}</b-button>
    </b-form>
    <b-modal ref="modalDetails" :title="user.username" no-close-on-backdrop>
      <b-form @submit.prevent="updateInfo" data-vv-scope="updateInfo">
        <div class="form-group">
          <label for="nick">Nick</label>
          <b-form-input v-validate="'required|max:30'" name="nick" id="nick" v-model="user.nick" :state="validateState('nick')" />
          <span class="error-inputs">{{ errors.first('nick') }}</span>
        </div>
        <div class="form-group">
          <label for="email">{{$t('email')}}</label>
          <b-form-input v-validate="'required|max:30'" name="email" id="email" v-model="user.email" :state="validateState('email')" />
          <span class="error-inputs">{{ errors.first('email') }}</span>
        </div>
        <div class="form-group">
          <label for="country">{{$t('country')}}</label>
          <multiselect v-validate="'required'" :state="validateState('country')" name="country" id="country" v-model="user.country" :options="availableCountries" deselectLabel="" track-by="name" label="name" :placeholder="$t('selectOption')" :searchable="true" :allow-empty="false" selectLabel="" selectedLabel="">
            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
          </multiselect>
          <span class="error-inputs">{{ errors.first('country') }}</span>
        </div>
      </b-form>
      <div slot="modal-footer">
        <b-button @click="$refs.modalDetails.hide()">{{$t('cancel')}}</b-button>
        <b-button variant="primary" @click="updateInfo" class="ml-3">{{$t('save')}}</b-button>
        <b-button variant="primary" :disabled="loading" @click="sendMessageChangePassword()" type="button" class="ml-3">{{$t('sendLinkResetPassword')}}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      user: {},
      availableCountries: [],
      console: this.$route.params.console
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'countries'
    ])
  },
  mounted() {
    document.title = `${this.$t('editInfoUser')} - ITFA`
    if (this.countries.length < 1) {
      this.fetchCountries()
    } else {
      this.setCountriesForSelect(this.countries)
    }
  },
  methods: {
    fetchCountries () {
      this.$axios.get('countries').then((response) => {
        const data = response.data.data
        this.$store.dispatch('SET_COUNTRIES', data)
        this.setCountriesForSelect(data)
      })
    },
    setCountriesForSelect (countries) {
      this.availableCountries = countries.map((country) => {
        return {
          id: country.id,
          name: country.name
        }
      })
    },
    findUser () {
      this.$validator.validateAll('formFind').then(result => {
        if (result) {
          const path = `auth/${this.lang}/${this.console}/administrator/find-by-edit/${this.user.id}`
          this.$axios.get(path).then((response) => {
            const data = response.data.data
            this.user = data
            this.user.country = this.availableCountries.filter((country) => {
              return country.id === data.country_id
            }).reduce((country) => {
              return country
            })
            this.$refs.modalDetails.show()
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    findUserById () {
      const path = `auth/${this.lang}/${this.console}/administrator/find/${this.userId}`
      this.$axios.get(path).then((response) => {
        const data = response.data.data
        this.nick = data.nick
        this.username = data.username
        this.$refs.modalAdd.hide()
        this.$refs.modalDetails.show()
      })
    },
    updateInfo () {
      this.$validator.validateAll('updateInfo').then(result => {
        if (result) {
          const path = `auth/${this.lang}/${this.console}/administrator/update-user`
          const payload = {
            id: this.user.id,
            nick: this.user.nick,
            email: this.user.email,
            country_id: this.user.country.id
          }
          this.$axios.post(path, payload).then(() => {
            this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
            this.$refs.modalDetails.hide()
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    sendMessageChangePassword () {
      const path = `auth/${this.lang}/${this.console}/administrator/send-email-change-paswword/${this.user.id}`
      this.$axios.post(path).then(() => {
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
        this.$refs.modalDetails.hide()
      })
    }
  }
}
</script>
